<template>
    <div>
      <!--#region DATOS INFORMATIVOS -->
      <div class="content-identification-information">
        <p class="text-identification-information">
          <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        </p>
        <v-container fluid>
          <v-row>
            <!-- #region LABELS AND TEXTS-FIELDS  -->

            <!-- #region IDENTIFICADOR DE TRANSACCION  -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <!-- v-if="!bModify" -->
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </div>
            </v-col>
            <!-- #endregion IDENTIFICADOR DE TRANSACCION  -->

            <!-- #region TIPO  DE TRANSACCIÓN  -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </div>
            </v-col>
            <!-- #endregion TIPO  DE TRANSACCIÓN -->

            <!-- #region CANTIDAD ASOCIADA -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </div>
            </v-col>
            <!-- #endregion CANTIDAD ASOCIADA -->

            <!-- #region TIPO DE REGISTRO -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </div>
            </v-col>
            <!-- #endregion TIPO DE REGISTRO -->

            <!-- #region FECHA DE  TRANSACCIÓN -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </div>
            </v-col>
            <!-- #endregion FECHA DE TRANSACCIÓN -->

            <!-- #region INGRESO DE TRNAS. EN EL SISTEMA -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </div>
            </v-col>
            <!-- #endregion INGRESO DE TRNAS. EN EL SISTEMA -->

            <!-- #endregion LABELS TEXTS-FIELDS -->
          </v-row>
        </v-container>
        <v-divider class="divider-global mt-9"></v-divider>
      </div>
      <!--#endregion DATOS INFORMATIVOS -->

      <!--#region INFORMACIÓN DEL RESPONSABLE  -->
      <div class="content-identification-information">
        <p class="text-identification-information">
          <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        </p>
        <v-container fluid>
          <v-row>
            <!-- #region LABELS AND TEXTS-FIELDS   -->
            <!-- #region EMPLEADO QUE REALIZO LA TRANS  -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <!-- v-if="!bModify" -->
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <span class="text-information-link">
                    <v-skeleton-loader
                      width="auto"
                      type="text"
                    ></v-skeleton-loader>
                  </span>
                </p>
              </div>
            </v-col>
            <!-- #endregion IDENTIFICADOR DE TRANSACCION  -->

            <!-- #region TELEFONO DEL RESPONSABLE  -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </div>
            </v-col>
            <!-- #endregion TIPO  DE TRANSACCIÓN -->

            <!-- #region CORREO ELECTRONICO -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </div>
            </v-col>
            <!-- #endregion CANTIDAD ASOCIADA -->

            <!-- #region CLIENTE ASOCIADO -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="text-information">
                  <v-skeleton-loader
                    width="auto"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </div>
            </v-col>
            <!-- #endregion TIPO DE REGISTRO -->

            <!-- #endregion LABELS TEXTS-FIELDS  -->
          </v-row>
        </v-container>
        <v-divider class="divider-global mt-9"></v-divider>
      </div>
      <!--#endregion INFORMACIÓN DEL RESPONSABLE -->
    </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },

};
</script>

<style scoped>
.content-identification-information {
  margin-bottom: 25px;
  padding: 0px 0px 0px 12px;
}

.text-identification-information {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 16px;
  letter-spacing: 3px;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}
.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}
.text-information-link {
  margin-bottom: 5px;
  color: var(--primary-color-link);
  font-family: "pop-Regular";
  cursor: pointer;
  text-decoration: underline;
}
.content-btns-accions {
  display: flex;
  padding: 0px 0px 0px 12px;
  margin-top: 25px;
  margin-bottom: 5px;
  width: 100% !important;
}
.content-btn-primary {
  width: 200px;
}
.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

.text-identification {
  margin-bottom: 0px;
  color: var(--primary-color-text);
}
.text-icon-launch {
  color: var(--primary-color-link);
  /* margin-top: -5px; */
  font-size: 20px;
  cursor: pointer;
}
</style>